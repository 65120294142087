import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { StartDisclaimerComponent } from 'src/app/adesioni/email-insert/start-disclaimer.component';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-email-insert',
  templateUrl: './email-insert.component.html',
  styleUrls: ['./email-insert.component.scss']
})
export class EmailInsertComponent implements OnInit {

  email: FormControl;
  trattamento: FormControl;
  isButtonClicked = false;
  isEmailInvalid = false;
  isEmailSent = false;
  isLoading = false;

  errorMessage: string;

  constructor(
    public afAuth: AngularFireAuth,
    private dialog: MatDialog
  ) { }

  ngOnInit() {

    this.email = new FormControl('', [ Validators.compose([Validators.required, Validators.email, Validators.maxLength(70)])]);
    this.trattamento = new FormControl(false, [Validators.requiredTrue]);
  }

  onOpenDisclaimer() {
    this.dialog.open(StartDisclaimerComponent, {
      width: '800px',
      height: '500px',
      disableClose: true,
    });
  }

  async sendEmailLink() {
    this.isButtonClicked = true;
    this.email.setValue(this.email.value.trim().toLowerCase());
    if (this.email.valid) {
      this.email.disable();
      this.isEmailInvalid = false;
      this.isLoading = true;
      const actionCodeSettings = {
        url: environment.domain + '/procedure',
        handleCodeInApp: true,
      };
      try {
        await this.afAuth.auth.sendSignInLinkToEmail(
          this.email.value,
          actionCodeSettings
        );
        localStorage.setItem('emailForSignIn', this.email.value);
        this.isEmailSent = true;
        this.isLoading = false;
      } catch (err) {
        this.errorMessage = err.message;
      }
    } else {
      this.isButtonClicked = false;
      this.isEmailInvalid = true;
    }
  }
}
