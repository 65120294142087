<div class="container">
    <div class="row">
        <div class="col">
            <div class="px-2">
                <h1>Pagamento</h1>
                <div class="d-flex align-items-center my-2">
                <span>La quota minima per aderire a Potere al Popolo! è di 15€.
                    <br>Ma se vuoi puoi sostenere
                    il progetto versando qualcosa in più!</span>
                    <mat-icon fontSet="material-icons-round" class="red-icon ml-2">loyalty</mat-icon>
                </div>
                <div class="d-flex align-items-center my-2">
                    <mat-icon fontSet="material-icons-round" class="blue-icon mr-2">verified</mat-icon>
                    <span>Il pagamento avviene attraverso la piattaforma <strong>Stripe</strong>, e può essere
                        effettuato con carta di
                        credito o carta prepagata, anche di una persona diversa dall'aderente.</span>
                </div>
                <p>Definisci l'importo che vuoi pagare cliccando sulle frecce, e poi procedi al pagamento.
                    <br><strong>Attenzione: una volta cliccato non si potrà modificare l'importo selezionato.</strong>
                </p>
            </div>
        </div>
    </div>
</div>
<div class="d-flex flex-column">
    <div class="d-flex justify-content-center">
        <button mat-icon-button color="warn" [disabled]="amount === 10" (click)="setAmount(false)">
            <mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
        </button>
        <div id="amount">{{amount}} €</div><button mat-icon-button color="warn" [disabled]="amount === 100"
            (click)="setAmount(true)">
            <mat-icon fontSet="material-icons-round">chevron_right</mat-icon>
        </button>
    </div>
    <div class="d-flex mt-4 justify-content-center">
        <button mat-stroked-button color="primary" (click)="buy()">
            {{ 'Procedi al pagamento' }}
        </button>
    </div>
</div>
