<div class="d-flex flex-column">
    <p>Inserisci nuovamente l'indirizzo email con cui hai effettuato la registrazione:</p>
    <mat-form-field>
        <input matInput placeholder="Indirizzo email" [formControl]="email"
        (keydown.enter)="sendEmail()"
            autocomplete="off" >
    </mat-form-field>
    <div class="d-flex justify-content-end">
        <button mat-flat-button color="primary"
            (click)="sendEmail()">Conferma</button>
    </div>
    <div *ngIf="isEmailInvalid" class="my-alert-danger mt-1">
        L'indirizzo inserito non è valido
    </div>
</div>