import { Component } from '@angular/core';

@Component({
  selector: 'app-info-documento',
  template: ` <div class="d-flex flex-column">
                <div class="flex-1">
                    <div class="overflow-y-auto">
                        <h2>Documento di identità</h2>
                        <p>
                                Devi caricare le foto dei due lati del tuo documento (che può essere Carta d’identità italiana,
                                Patente italiana o Passaporto di qualsiasi paese).<br>
                                <strong>Il peso delle due foto non può superare i 450 KB ciascuna, le immagini devono essere in uno
                                dei seguenti formati: jpg, jpeg, png, gif.</strong>
                                Se effettui l’adesione tramite <em>smart phone</em> puoi scattare direttamente le foto mentre sei
                                sulla scheda di adesione, impostando una bassa risoluzione sulla fotocamera del dispositivo.
                                Se non sei in grado di impostare una bassa risoluzione è meglio che ti prepari prima le foto,
                                comprimendole con un programma apposito o semplicemente inviandotele tramite WathsApp o Telegram,
                                che comprimono automaticamente le immagini.
                        </p>
                    </div>
                </div>

                <div class="d-flex justify-content-end" style="height: 28px">
                    <button style="width: 0px; height: 0px;" cdkFocusInitial mat-button></button>
                    <button mat-flat-button color="primary" mat-dialog-close>Ok</button>
                </div>
              </div>`
})
export class InfoDocumentoComponent {
  constructor() { }
}

