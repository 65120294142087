<div style="height:100%; width:100%">
    <app-big-header></app-big-header>
    <main style="overflow: hidden;">
        <div class="row no-gutters justify-content-center" style="margin: 0px">
            <div class="col-12" style="max-width: 600px; padding: 0 12px">
                <p style="font-size: larger; font-weight: 500;">Ciao!</p>
                <p><strong>Sei qui per aderire a Potere al Popolo, giusto?</strong><br>
                    È un'operazione molto semplice, ci vorranno solo pochi minuti.</p>
                <strong>Ti servono:</strong>
                <div style="margin-left: 75px">
                    <div class="d-flex align-items-center mb-2">
                        <mat-icon class="mr-1" fontSet="material-icons-outlined">email</mat-icon>
                        <span>un indirizzo email personale</span>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <mat-icon class="mr-1" fontSet="material-icons-outlined">portrait</mat-icon>
                        <span>i tuoi dati personali e gli estremi di un tuo documento (Carta d'identità o Patente italiane, o
                            Passaporto di qualsiasi paese)</span>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <mat-icon class="mr-1" fontSet="material-icons-outlined">payment</mat-icon>
                        <span>una carta di credito o prepagata (la quota minima di adesione è 15€)</span>
                    </div>
                </div>
                <p style="margin-top: 0px">Per iniziare leggi l'informativa (cliccando sul link), inserisci la tua email nel campo sottostante e
                    conferma.
                    Ti verrà inviata una email, con un link da cliccare per proseguire la procedura di adesione.
                </p>
                <div class="mb-1">
                    <mat-checkbox [formControl]="trattamento" class="mr-2"></mat-checkbox>
                    <strong>Sì, ho letto <span class="link-modale" (click)="onOpenDisclaimer()">l'informativa GDPR</span> ed autorizzo il trattamento dei miei dati personali per le finalità ivi indicate</strong>
                </div>
                <div class="d-flex w-100">
                    <div class="flex-1 d-flex align-items-center">
                        <mat-form-field appearance="outline" id="email" style="width: 100%">
                            <input matInput placeholder="Indirizzo email" [formControl]="email"
                                (keydown.enter)="sendEmailLink()" autocomplete="off">
                        </mat-form-field>
                    </div>
                    <div style="flex: 0 0 40px; min-width: 40px; padding: 9px 8px">
                        <mat-spinner *ngIf="isLoading; else placeholder" class="mr-2" style="margin-top: 1px"
                            diameter="24">
                        </mat-spinner>
                        <ng-template #placeholder><span style="width: 24px; height: 24px"></span></ng-template>
                    </div>
                    <div class="d-flex align-items-center" style="flex: 0 0 94px; min-width: 80px;">
                        <button mat-flat-button color="primary" [disabled]="isButtonClicked || trattamento.invalid"
                            (click)="sendEmailLink()">Conferma</button>
                    </div>
                </div>

                <div class="d-flex justify-content-end">

                </div>
                <div *ngIf="isEmailInvalid" class="my-alert-danger mt-1">
                    L'indirizzo inserito non è valido
                </div>
                <div *ngIf="isEmailSent" style="margin-top: 6px">
                    <div class="d-flex align-items-center light-purple-bg-border-container"
                        style="margin: 0 0 8px 0 !important">
                        <mat-icon class="mr-1" fontSet="material-icons-outlined">mark_email_read</mat-icon>
                        <span>L'email è stata inviata al tuo indirizzo, aprila per proseguire la procedura di adesione.
                        </span>
                    </div>
                    <div class="d-flex align-items-center light-purple-bg-border-container" style="margin: 0 0 8px 0 !important">
                        <mat-icon class="mr-1" fontSet="material-icons-outlined">report_problem</mat-icon>
                        <span>Nel caso non la dovessi trovare in <em>posta in arrivo</em> cerca nelle cartelle <em>posta
                                indesiderata</em> o <em>spam</em>, potrebbe essere stata bloccata dalle impostazioni
                            della tua casella email.</span>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
