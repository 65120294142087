import { SignUpService } from 'src/app/shared/services/sign-up.service';
import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

interface ImageId {
  id: number;
  name: string;
  type: string;
  image: string;
}

@Component({
  selector: 'app-document-image-upload',
  templateUrl: './document-image-upload.component.html',
  styleUrls: ['./document-image-upload.component.scss']
})
export class DocumentImageUploadComponent implements OnInit {

  @Input() fetchCallback: (...args: any) => {};
  @Input() buttonLabel: string;
  @Input() initialImage: string;
  @Output() imageId = new EventEmitter<number>();

  selectedFile: File;
  base64Data: string;
  convertedImage: string;
  uploadPending = false;
  isImagePresent = false;
  isError = false;
  isTooBig = false;
  isDifferentType = false;

  constructor() { }

  ngOnInit() {
    if (this.initialImage) {
      this.isImagePresent = true;
    }
  }

  onUploadImage(event: any) {
    this.uploadPending = true;
    this.isError = false;
    this.isTooBig = false;
    this.isDifferentType = false;
    const uploadData = new FormData();
    this.selectedFile = event.target.files[0];
    if (this.isGoodFile(this.selectedFile.name)) {
      if (this.selectedFile.size < 470000) {
        uploadData.append('myFile', this.selectedFile, this.selectedFile.name);
        this.fetchCallback.apply(this, [uploadData])
          .subscribe((receivedImage: ImageId) => {
            this.uploadPending = false;
            this.base64Data = receivedImage.image;
            this.convertedImage = 'data:' + receivedImage.type + ';base64,' + this.base64Data;
            this.imageId.emit(receivedImage.id);
            this.isImagePresent = true;
          }, (err: HttpErrorResponse) => {
            this.isError = true;
            this.uploadPending = false;
          });
      } else {
        this.isTooBig = true;
        this.uploadPending = false;
      }
    } else {
      this.isDifferentType = true;
      this.uploadPending = false;
    }
  }

  isGoodFile(name: string) {
    const exten = name.substr(name.lastIndexOf('.') + 1).toLowerCase();
    switch (exten) {
      case 'gif': case 'jpeg': case 'jpg': case 'png': return true;
      default: return false;
    }
  }
}
