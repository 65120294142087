import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry } from 'rxjs/operators';
import { AdherentReceived } from '../models/adherent-received.model';

export const apis = {
  check: environment.apis.signup + '/check',
  image: environment.apis.signup + '/image',
  submit: environment.apis.signup + '/submit',
};

@Injectable()
export class SignUpService {

  constructor(
    private http: HttpClient
  ) { }

  check(emailAddress: string) {
    return this.http.get(apis.check, { params: {emailAddress: emailAddress}});
  }

  signUp(adherent: AdherentReceived) {
    return this.http.post(apis.submit, adherent);
  }

  uploadImage(uploadData: FormData) {
    return this.http.post(apis.image, uploadData);
  }
}
