import { Component } from '@angular/core';

@Component({
  selector: 'app-start-disclaimer',
  // template: ` <div class="d-flex flex-column">
  //               <div class="flex-1">
  //                   <div class="d-flex align-items-start">
  //                       <mat-icon fontSet="material-icons-outlined"
  //                         style="font-size: 40px; height: 40px; width: 40px; line-height: 40px; margin-right: 8px">verified</mat-icon>
  //                       <p>I dati personali inseriti saranno gestiti secondo le norme del GDPR.</p>
  //                   </div>
  //                   <p>
  //                       Leggi attentamente le informazioni relative all'utilizzo dei <strong>cookies</strong> (<a
  //                       href="https://adesioni.poterealpopolo.org/dataprivacy" target="_blank">in questa pagina</a>).
  //                   </p>
  //               </div>

  //               <div class="d-flex justify-content-end" style="height: 28px">
  //                   <button style="width: 0px; height: 0px;" cdkFocusInitial mat-button></button>
  //                   <button mat-raised-button color="accent" mat-dialog-close>Ok</button>
  //               </div>
  //             </div>`
  template: ` <div class="d-flex flex-column">
    <div class="flex-1">
      <button style="width: 0px; height: 0px;" cdkFocusInitial mat-button></button>
      <!DOCTYPE html>
      <html lang="it">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Informativa sulla privacy in conformità al Regolamento generale sulla protezione dei dati (GDPR)</title>
      </head>
      <body>
      <h1>Informativa sulla privacy in conformità al Regolamento generale sulla protezione dei dati (GDPR)</h1>
      <p>Gentile Utente, La presente informativa sulla privacy descrive come “Potere al Popolo!” raccoglie, utilizza e protegge i dati personali degli utenti. Ci impegniamo a garantire la protezione e la riservatezza delle informazioni personali fornite dagli utenti attraverso il nostro sito web.</p>

      <h3>a) Titolare del trattamento dei dati personali</h3>
      <p>Il Titolare del trattamento è “Potere al Popolo!” (di seguito anche il “Titolare” o “PAP”), con sede in Napoli, Via Filippo Bottazzi 38, in persona del suo legale rappresentante pro tempore.</p>
      <p>Il Titolare del Trattamento è raggiungibile ai seguenti recapiti:
      <ul>
        <li>indirizzo mail: poterealpopolo2018@gmail.com</li>
        <li>indirizzo pec: poterealpopolo@pec.it</li>
        <li>Pagina di Contatto del sito</li>
      </ul>

      <h3>b) Responsabile della protezione dei dati (DPO)</h3>
      <p>L'associazione, ai sensi dell’articolo 37 del RGPD EU 2016/679, ha proceduto ad individuare e nominare il Data Protection Officer (DPO) contattabile all’indirizzo email dpo.poterealpopolo@gmail.com.</p>

      <h3>c) Dati personali trattati</h3>
      <p>Il nostro sito web può raccogliere i seguenti dati personali dagli utenti:
      <ul>
        <li>Nome e cognome</li>
        <li>Luogo di nascita</li>
        <li>Data di nascita</li>
        <li>Codice fiscale</li>
        <li>Indirizzo di residenza</li>
        <li>Domicilio e/o luogo dell’attività politica</li>
        <li>Indirizzo email</li>
        <li>Numero di telefono</li>
        <li>Indirizzo IP</li>
        <li>Informazioni sul browser e sul dispositivo utilizzato</li>
        <li>Tipo e numero documento di identità</li>
      </ul>
      <p>
        Tali dati, comportando una Sua implicita adesione alle iniziative politiche del Titolare del trattamento, verranno considerati “dati particolari” ai sensi dell’art. 9 del GDPR. I dati di navigazione vengono utilizzati da Potere al Popolo! al solo fine di ricavare informazioni statistiche anonime sull’uso del sito. I dati acquisiti potrebbero essere utilizzati infine per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito e potranno essere esibiti all’Autorità Giudiziaria, qualora questa ne facesse esplicita richiesta.
      </p>

      <!-- Altri paragrafi e intestazioni seguono lo stesso modello -->

      <h3>l) Diritti dell’interessato</h3>
      <p>Ai sensi e nei limiti di cui agli artt. 15 e ss. Regolamento UE 2016/679, hai il diritto di chiedere a “Potere al Popolo!”, in qualunque momento, l’accesso ai tuoi Dati Personali, la rettifica o la cancellazione degli stessi o di opporti al loro trattamento, la limitazione del trattamento nonché di ottenere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati che ti riguardano.
        Gli interessati che ritengono che il trattamento dei dati personali a loro riferiti avvenga in violazione di quanto previsto dal Regolamento hanno il diritto di proporre reclamo al Garante, come previsto dall’art. 77 del Regolamento stesso, o di adire le opportune sedi giudiziarie (art. 79 del Regolamento).
        L’interessato/a può esercitare i Suoi diritti con richiesta scritta inviata all’indirizzo di posta elettronica certificata: poterealpopolo@pec.it o a mezzo lettera raccomandata A/R all’indirizzo “Potere al Popolo!” Via Filippo Bottazzi 38, 80126, Napoli.
      </p>

      <h3>m) Modifiche alla presente informativa sulla privacy</h3>
      <p>La presente informativa sulla privacy potrà essere aggiornata periodicamente per garantire la rispondenza delle finalità del trattamento effettuato. Gli utenti sono, pertanto, invitati a verificare periodicamente questa pagina web per essere informati sulle eventuali modifiche.
        Se avete domande o dubbi riguardo alla presente informativa sulla privacy, vi preghiamo di contattarci all'indirizzo email sopra indicato.
      </p>
    <div class="d-flex justify-content-start">
      <a href="https://poterealpopolo.org/wp-content/uploads/2024/INFORMATIVA%20PRIVACY%202024.pdf" target="_blank">
        Puoi leggere l'informativa sul trattamento dei dati personali qui
      </a>
    </div>
    <div class="d-flex justify-content-end my-2" style="height: 28px">
      <button mat-raised-button color="accent" mat-dialog-close>Ok</button>
    </div>`
})
export class StartDisclaimerComponent {
  constructor() { }
}

