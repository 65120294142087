import { BigHeaderComponent } from './components/big-header/big-header.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { DocumentImageUploadComponent } from './components/document-image-upload/document-image-upload.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntlIt } from 'src/app/shared/mat-paginator-intl-it';
import { DataPrivacyComponent } from 'src/app/shared/components/data-privacy/data-privacy.component';
import { GenericDialogContentComponent } from 'src/app/shared/components/generic-dialog-content/generic-dialog-content.component';
import { NotfoundComponent } from 'src/app/shared/components/notfound/notfound.component';
import { PrivacyContainerComponent } from 'src/app/shared/components/privacy-container/privacy-container.component';
import { ErrorPageComponent } from 'src/app/shared/components/error-page/error-page.component';

const declarationsArr = [
    PrivacyComponent,
    DocumentImageUploadComponent,
    GenericDialogContentComponent,
    DataPrivacyComponent,
    NotfoundComponent,
    PrivacyContainerComponent,
    BigHeaderComponent,
    ErrorPageComponent
];

const importsArr = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule
];

@NgModule({
    declarations: declarationsArr,
    imports: importsArr,
    exports: [...declarationsArr, ...importsArr],
    providers: [
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlIt },
    ]
})
export class SharedModule {}
