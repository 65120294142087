import { InfoDomicilioComponent } from './adesioni/stepper/dialogs/info-domicilio.component';
import { NotfoundComponent } from './shared/components/notfound/notfound.component';
import { EmailInsertComponent } from './adesioni/email-insert/email-insert.component';
import { SignUpService } from './shared/services/sign-up.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DatePipe, registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import localeIt from '@angular/common/locales/it';

import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { SharedModule } from './shared/shared.module';
import { AppGuard } from 'src/app/authentication/app.guard';
import { LoginFailedComponent } from 'src/app/authentication/login-failed/login-failed.component';
import { LoginComponent } from 'src/app/authentication/login/login.component';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { StepperComponent } from 'src/app/adesioni/stepper/stepper.component';
import { PaymentComponent } from 'src/app/adesioni/stepper/payment/payment.component';
import { ReInsertEmailComponent } from 'src/app/adesioni/stepper/re-insert-email/re-insert-email.component';
import { AuthInterceptorService } from 'src/app/authentication/auth-interceptor.service';
import { DataPrivacyComponent } from 'src/app/shared/components/data-privacy/data-privacy.component';
import { InfoDocumentoComponent } from 'src/app/adesioni/stepper/dialogs/info-documento.component';
import { InfoGeneraliComponent } from 'src/app/adesioni/stepper/dialogs/info-generali.component';
import { StartDisclaimerComponent } from 'src/app/adesioni/email-insert/start-disclaimer.component';
import { TrasparenzaDetailComponent } from 'src/app/adesioni/stepper/dialogs/trasparenza-detail.component';
import { PrivacyContainerComponent } from 'src/app/shared/components/privacy-container/privacy-container.component';
import { ErrorPageComponent } from 'src/app/shared/components/error-page/error-page.component';

registerLocaleData(localeIt);

const routes: Routes = [
  { path: '', component: EmailInsertComponent, pathMatch: 'full' },
  { path: 'procedure', component: StepperComponent, pathMatch: 'full' },
  { path: 'privacy', component: PrivacyContainerComponent, pathMatch: 'full' },
  { path: 'try-later', component: ErrorPageComponent, pathMatch: 'full' },
  { path: 'cookies', component: DataPrivacyComponent, pathMatch: 'full' },
  { path: 'ad-manager', component: LoginComponent, pathMatch: 'full'},
  { path: 'ad2020', loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule), canActivate: [AppGuard] },
  { path: '**', component: NotfoundComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginFailedComponent,
    EmailInsertComponent,
    StepperComponent,
    InfoDocumentoComponent,
    InfoGeneraliComponent,
    InfoDomicilioComponent,
    PaymentComponent,
    ReInsertEmailComponent,
    StartDisclaimerComponent,
    TrasparenzaDetailComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading}),
    SharedModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  entryComponents: [
    InfoDocumentoComponent,
    InfoGeneraliComponent,
    InfoDomicilioComponent,
    ReInsertEmailComponent,
    StartDisclaimerComponent,
    TrasparenzaDetailComponent
  ],
  providers: [
    AppGuard,
    AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    DatePipe,
    { provide: LOCALE_ID, useValue: 'it-IT' },
    SignUpService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
