<div class="d-flex justify-content-center flex-column">
  <div class="d-flex justify-content-center align-items-center">
    <label class="upload-button">
      <span *ngIf="!base64Data && !initialImage">Carica foto {{buttonLabel}} documento</span><span *ngIf="base64Data || initialImage">Carica altra
        foto in
        sostituzione</span>
      <input accept="image/x-png,image/gif,image/jpeg" type="file" (change)="onUploadImage($event)">
    </label>
    <mat-spinner *ngIf="uploadPending" diameter="24" style="margin-left: 6px"></mat-spinner>
  </div>
  <div *ngIf="!isImagePresent && !isTooBig && isError" style="margin: 6px; color: crimson; text-align: center;">Nessuna immagine
    presente</div>
  <div *ngIf="isTooBig" style="margin: 6px; color: crimson; text-align: center">L'immagine supera i 450 KB consentiti.</div>
    <div *ngIf="isError" style="margin: 6px; color: crimson; text-align: center">Errore nel caricamento.<br>Potresti aver già caricato il numero massimo di immagini consentite, scrivi a assistenza.adesioni@poterealpopolo.org</div>
    <div *ngIf="isDifferentType" style="margin: 6px; color: crimson; text-align: center">Questo tipo di immagine non è consentito. Le tipologie di immagini supportate sono: jpg, jpeg, png, gif.</div>
  <div *ngIf="convertedImage || initialImage">
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-center">
        <strong>{{buttonLabel | titlecase}}:</strong>
      </div>
      <div class="d-flex justify-content-center" style="margin-top: 12px;">
        <ng-container *ngIf="!uploadPending;">
          <img *ngIf="convertedImage" [src]="convertedImage" style="max-width: 100%; margin-bottom: 10px">
          <img *ngIf="!convertedImage && initialImage" [src]="initialImage" style="max-width: 100%; margin-bottom: 10px">
        </ng-container>
      </div>
    </div>
  </div>
</div>