import { Component } from '@angular/core';

@Component({
  selector: 'app-info-generali',
  template: ` <div class="d-flex flex-column">
                <div class="flex-1">
                    <div class="overflow-y-auto">
                        <h2>Informazioni generali</h2>
                        <p>
                                Possono aderire solo i nati e le nate prima del<br><strong>1 gennaio 2008</strong>,
                                altrimenti l’adesione non potrà essere completata.<br>
                                Per inoltrare la richiesta di adesione devi compilare tutti i campi,
                                l’<strong>unico campo facoltativo</strong> è quello dove puoi indicare l’Assemblea
                                territoriale a cui appartieni: se vuoi puoi lasciarlo vuoto.
                        </p>
                    </div>
                </div>

                <div class="d-flex justify-content-end" style="height: 28px">
                    <button style="width: 0px; height: 0px;" cdkFocusInitial mat-button></button>
                    <button mat-flat-button color="primary" mat-dialog-close>Ok</button>
                </div>
              </div>`
})
export class InfoGeneraliComponent {
  constructor() { }
}

