<div class="h-100 w-100 py-5">
    <header style="overflow:hidden; display: flex; align-items: center; justify-content: center">
        <img src="assets/images/pap-logo.png">
    </header>
    <main style="overflow: hidden;">
        <div class="row no-gutters justify-content-center h-100 py-4" style="margin: 0px">
            <div class="col-12" style="max-width: 360px; padding: 0 12px">
                <form #f="ngForm" (ngSubmit)="login()" novalidate>
                    <mat-form-field appearance="outline" id="email" style="width: 100%">
                        <input matInput name="email" type="email" required email placeholder="Indirizzo email" [(ngModel)]="email" maxlength="70"
                            autocomplete="off">
                    </mat-form-field>
                    <mat-form-field appearance="outline" id="password" style="width: 100%">
                        <input matInput name="password" type="password" required placeholder="Password" [(ngModel)]="password" maxlength="70"
                            autocomplete="off" (keydown.enter)="login()">
                    </mat-form-field>
                    <div class="d-flex justify-content-end">
                        <mat-spinner *ngIf="isLoading" class="mr-2 mt-1" style="margin-top: 1px" diameter="24"></mat-spinner>
                        <button mat-flat-button color="primary" [disabled]="form.invalid || form.pristine">Conferma</button>
                    </div>
                </form>
            </div>
        </div>
    </main>
</div>