import { Component } from '@angular/core';

@Component({
  selector: 'app-trasparenza-detail',
  template: ` <div class="d-flex flex-column">
                <div class="flex-1">
                    <div class="overflow-y-auto">
                    <h3 style="margin: 0.5rem 0 0 0 !important">L. 3/2019</h3>
                    <button style="width: 0px; height: 0px;" cdkFocusInitial mat-button></button>
                                            <p>Ai sensi della legge del 9 gennaio 2019, n. 3 dichiaro che:</p>
                                            <ol>
                                                <li>nel caso in cui il contributo da me erogato a partiti e/o movimenti
                                                    politici sia superiore ad Euro 500,00 (cinquecento) nell'anno, non
                                                    sono contrario alla pubblicazione dei miei dati personali;</li>
                                                <li>sono iscritto nelle liste elettorali e ho il pieno godimento del
                                                    diritto di voto;</li>
                                                <li>non sono un Governo, un ente pubblico di uno Stato estero, né una
                                                    persona giuridica avente sede in uno Stato estero non assoggettata
                                                    ad obblighi fiscali in Italia;</li>
                                                <li>non sono un organo della pubblica amministrazione, un ente pubblico,
                                                    una società con partecipazione di capitale pubblico superiore al 20
                                                    per cento, né una società controllata da queste ultime; </li>
                                                <li>non sono una società con partecipazione di capitale pubblico pari o
                                                    inferiore al 20 per cento, né una società controllata da
                                                    quest'ultima, ove tale partecipazione assicuri al soggetto pubblico
                                                    il controllo della società;</li>
                                                <li>nel caso di società diverse da quelle di cui al superiore punto 5):
                                                    il finanziamento o il contributo è stato deliberato dall'organo
                                                    sociale competente, è stato regolarmente iscritto in bilancio e non
                                                    è vietato dalla legge;</li>
                                                <li>non sono una cooperativa sociale, né un consorzio disciplinato dalla
                                                    legge 381/91.</li>
                                            </ol>
                    </div>
                </div>

                <div class="d-flex justify-content-end" style="height: 28px">
                    <button mat-flat-button color="primary" mat-dialog-close>Ok</button>
                </div>
              </div>`
})
export class TrasparenzaDetailComponent {
  constructor() { }
}

