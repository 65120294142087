<div class="d-flex flex-column" style="height: 100%; width: 100%; overflow:hidden">
    <header style="display: flex; height: 40px">
        <div style="flex: 1">
            <h1>{{title}}</h1>
        </div>
        <div style="flex: 0 0 30px; display: flex; justify-content: flex-end; align-items: flex-start">
            <button mat-icon-button mat-dialog-close color="primary" style="float:right">
                <mat-icon fontSet="material-icons-round">close</mat-icon>
            </button>
        </div>
    </header>
    <mat-divider style="margin-bottom: 6px"></mat-divider>
    <div class="flex-1">
        <div class="position-relative" style="height: 100%">
            <div class="overflow-y-auto">
                <ng-content class="flex-1" select="[id=body]"></ng-content>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end">
        <button id="hidden-button" cdkFocusInitial mat-button></button>
        <ng-content select="[id=footer]"></ng-content>
    </div>
</div>