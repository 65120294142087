<app-big-header></app-big-header>
<div class="d-flex justify-content-center px-5 pb-1">
   <div style="max-width: 600px">
      <h2>Informazioni sull'utilizzo dei cookie in questo sito web</h2>
      <p>Un cookie è una piccola quantità di dati memorizzati sul disco fisso del tuo computer.</p>
      <p>Il cookie viene poi riletto e riconosciuto dal sito web che lo ha inviato ogni qualvolta effettui una
         connessione successiva.
         <strong>Il nostro sito web utilizza i cookie di Stripe</strong>, la piattaforma di pagamenti online che utilizziamo per il
         pagamento della quota di adesione.</p>
      <p>Oltre ai cookies il nostro sito web utilizza il <strong>Web Storage</strong> del browser per mantenere le credenziali.</p>
   </div>
</div>