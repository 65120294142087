<div style="height:100%; width:100%">
    <header style="overflow:hidden; display: flex; align-items: center; justify-content: center">
        <img src="assets/images/titolo-form.jpg">
    </header>
    <main style="overflow: hidden;">
        <div class="row justify-content-center" style="margin: 0px">
            <div class="col-12" style="max-width: 640px; padding: 0 12px">
                <div *ngIf="loading; else loaded">
                    <div class="d-flex justify-content-center">
                        <div class="d-flex flex-column">
                            <div class="d-flex justify-content-center mt-2">
                                <mat-spinner></mat-spinner>
                            </div>
                            <div style="margin-top: 12px; text-align: center; max-width: 300px">
                                <em>{{waitingMessage}}</em></div>
                        </div>
                    </div>
                </div>
                <ng-template #loaded>
                    <div *ngIf="forbidden; else success" class="d-flex flex-column justify-content-center">
                        <div *ngIf="!emailExists; else email" class="my-alert-danger">
                            C'è stato un errore. Se stai accedendo da una rete aziendale o da una VPN, potrebbe essere a
                            causa delle restrizioni interne.
                            In tal caso prova ad accedere da un'altro dispositivo o a spegnere la VPN, altrimenti
                            riprova più tardi.
                            Se l'errore persiste contattaci scrivendo a assistenza.adesioni@poterealpopolo.org
                        </div>
                        <ng-template #email>
                            <div class="d-flex justify-content-center mt-4 w-100">
                                <div *ngIf="!showPayment; else payment" style="max-width: 600px">
                                    <div class="d-flex align-items-center white-bg-border-container">
                                        <mat-icon class="mr-2" fontSet="material-icons-round">error_outline</mat-icon>
                                        <span>La procedura non può proseguire perché l'indirizzo email inserito risulta
                                            già
                                            collegato ad un'altra richiesta di adesione.</span>
                                    </div>
                                    <div class="d-flex align-items-center white-bg-border-container">
                                        <mat-icon class="mr-2" fontSet="material-icons-round">help_outline</mat-icon>
                                        <span>Se hai bisogno di aiuto contattaci
                                            scrivendo all'indirizzo email assistenza.adesioni@poterealpopolo.org</span>
                                    </div>
                                    <div class="d-flex align-items-center white-bg-border-container">
                                        <mat-icon class="mr-2" fontSet="material-icons-round">payment</mat-icon>
                                        <span>Se hai compilato la richiesta ma devi ancora effettuare il pagamento
                                            <span class="payment-link" (click)="showPayment = true">clicca qui per
                                                pagare la
                                                quota di adesione</span>.</span>
                                    </div>
                                </div>
                                <ng-template #payment>
                                    <div style="max-width: 600px">
                                        <app-payment [email]="emailSubmitted"></app-payment>
                                    </div>
                                </ng-template>
                            </div>

                        </ng-template>
                    </div>
                    <ng-template #success>
                        <mat-horizontal-stepper linear>
                            <!--
                              ****************************************************************************************
                              ********************************   DATI ADERENTE   *************************************
                              ****************************************************************************************
                            -->
                            <mat-step id="userData" [stepControl]="userDataForm"
                                [label]="mobileQuery.matches ? '' : 'Dati aderente'">

                                <form [formGroup]="userDataForm" autocomplete="off">
                                    <div style="overflow-y: auto; height: calc(100vh - 170px);">
                                        <div class="px-2">
                                            <div class="container">

                                                <!-- <div class="row no-gutters">
                                                    <div class="col-sm-6">
                                                        <button mat-flat-button color="primary" (click)="onGetInfo()">
                                                            <mat-icon fontSet="material-icons-round">info</mat-icon> Aiuto alla compilazione
                                                        </button>
                                                    </div>
                                                </div> -->
                                                <div class="d-flex justify-content-end" style="width: 100%">
                                                    <button mat-icon-button class="info-button" color="primary"
                                                        matTooltip="Aiuto"
                                                        aria-label="Bottone per aprire le informazioni sulla sezione"
                                                        (click)="onOpenInfo(0)"
                                                        [ngClass]="mobileQuery.matches ? 'mr-1' : ''">
                                                        <mat-icon fontSet="material-icons-round" class="info-icon">help
                                                        </mat-icon>
                                                    </button>
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col-sm-6">
                                                        <mat-form-field id="name" style="width: 100%">
                                                            <input matInput placeholder="Nome *" formControlName="name"
                                                                maxlength="50" autocomplete="off"
                                                                (focus)="isNameFocused = true"
                                                                (blur)="isNameFocused = false" autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <mat-form-field id="surname" style="width: 100%">
                                                            <input matInput placeholder="Cognome *"
                                                                formControlName="surname" maxlength="50"
                                                                autocomplete="off" (focus)="isSurnameFocused = true"
                                                                (blur)="isSurnameFocused = false">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col-sm-6">
                                                        <mat-form-field id="birthPlace" style="width: 100%">
                                                            <input matInput placeholder="Luogo di nascita *"
                                                                formControlName="birthPlace" maxlength="70"
                                                                autocomplete="off" (focus)="isBirthPlaceFocused = true"
                                                                (blur)="isBirthPlaceFocused = false">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-6 d-flex">
                                                        <span
                                                            style="padding-top: 15px; height: 40px; color: #757575; flex: 0 1 120px;">Data
                                                            di nascita: *</span>
                                                        <mat-form-field style="width: 50px">
                                                            <input matInput type="number" autocomplete="off"
                                                                placeholder="Giorno" formControlName="birthDay"
                                                                name="birthDay" (focus)="isBirthDayFocused = true"
                                                                (blur)="isBirthDayFocused = false">
                                                        </mat-form-field><span
                                                            style="padding: 15px 4px 0 2px; height: 40px; color: #757575;">/</span>
                                                        <mat-form-field style="width: 50px">
                                                            <input matInput type="number" placeholder="Mese"
                                                                autocomplete="off" formControlName="birthMonth"
                                                                name="birthMonth" (focus)="isBirthMonthFocused = true"
                                                                (blur)="isBirthMonthFocused = false">
                                                        </mat-form-field><span
                                                            style="padding: 15px 4px 0 2px; height: 40px; color: #757575;">/</span>
                                                        <mat-form-field style="width: 50px">
                                                            <input matInput type="number" placeholder="Anno"
                                                                autocomplete="off" formControlName="birthYear"
                                                                name="birthYear" (focus)="isBirthYearFocused = true"
                                                                (blur)="isBirthYearFocused = false">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col-sm-6">
                                                        <mat-form-field id="fiscalCode" style="width: 100%">
                                                            <input matInput placeholder="Codice fiscale *"
                                                                formControlName="fiscalCode" maxlength="16"
                                                                autocomplete="off" (focus)="isFiscalCodeFocused = true"
                                                                (blur)="isFiscalCodeFocused = false">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col">
                                                        <div class="my-alert-danger"
                                                            *ngIf="(!isNameFocused && name.hasError('pattern'))
                                        || (!isSurnameFocused && surname.hasError('pattern'))
                                        || (!isBirthPlaceFocused && birthPlace.hasError('pattern'))
                                        || (!isFiscalCodeFocused && fiscalCode.hasError('pattern'))
                                        || (!isBirthDayFocused && (birthDay.hasError('pattern') || birthDay.hasError('max') || birthDay.hasError('min')))
                                        || (!isBirthMonthFocused && (birthMonth.hasError('pattern') || birthMonth.hasError('max') || birthMonth.hasError('min')))
                                        || (!isBirthYearFocused && (birthYear.hasError('pattern') || birthYear.hasError('max') || birthYear.hasError('min')))">
                                                            <div *ngIf="name.hasError('pattern')">
                                                                Il nome contiene caratteri non validi.</div>
                                                            <div *ngIf="surname.hasError('pattern')">
                                                                Il cognome contiene caratteri non validi.</div>
                                                            <div *ngIf="birthPlace.hasError('pattern')">
                                                                Il luogo di nascita contiene caratteri non validi.</div>
                                                            <div *ngIf="fiscalCode.hasError('pattern')">
                                                                Il codice fiscale non è valido.</div>
                                                            <div
                                                                *ngIf="(!isBirthDayFocused && (birthDay.hasError('pattern') || birthDay.hasError('max') || birthDay.hasError('min')))
                                                            || (!isBirthMonthFocused && (birthMonth.hasError('pattern') || birthMonth.hasError('max') || birthMonth.hasError('min')))
                                                            || (!isBirthYearFocused && (birthYear.hasError('pattern') || birthYear.hasError('max') || birthYear.hasError('min')))">
                                                                La data di nascita inserita non è valida. Ricorda che
                                                                può
                                                                aderire solo chi compie 14 anni entro il 2024.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="container">
                                                <div class="d-flex justify-content-between" style="padding-left: 6px">
                                                    <h3><strong>Documento di Identità</strong></h3>
                                                    <!--
                                                    <button mat-icon-button class="info-button" color="primary"
                                                        matTooltip="Aiuto"
                                                        aria-label="Bottone per aprire le informazioni sulla sezione"
                                                        (click)="onOpenInfo(1)"
                                                        [ngClass]="mobileQuery.matches ? 'mr-1' : ''">
                                                        <mat-icon fontSet="material-icons-round" class="info-icon">help
                                                        </mat-icon>
                                                    </button>
                                                    -->
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col-sm-3">
                                                        <mat-form-field id="documentType" style="width: 100%">
                                                            <mat-label>Tipo documento *</mat-label>
                                                            <mat-select formControlName="documentType"
                                                                (selectionChange)="onDocumentTypeSelectionChange()">
                                                                <mat-option value="C.I.">Carta di identità</mat-option>
                                                                <mat-option value="PAT.">Patente di guida</mat-option>
                                                                <mat-option value="PAS.">Passaporto</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <mat-form-field *ngIf="documentType.value === 'PAS.'"
                                                            id="passport-country" style="width: 100%">
                                                            <mat-label>Rilasciato da *</mat-label>
                                                            <mat-select formControlName="passportCountry">
                                                                <mat-option value="Italia">Italia</mat-option>
                                                                <mat-optgroup label="Europa">
                                                                    <mat-option *ngFor="let country of europe"
                                                                        [value]="country.townId">
                                                                        {{country.townName}}</mat-option>
                                                                </mat-optgroup>
                                                                <mat-optgroup label="Africa">
                                                                    <mat-option *ngFor="let country of africa"
                                                                        [value]="country.townName">
                                                                        {{country.townName}}</mat-option>
                                                                </mat-optgroup>
                                                                <mat-optgroup label="America">
                                                                    <mat-option *ngFor="let country of america"
                                                                        [value]="country.townName">
                                                                        {{country.townName}}</mat-option>
                                                                </mat-optgroup>
                                                                <mat-optgroup label="Asia">
                                                                    <mat-option *ngFor="let country of asia"
                                                                        [value]="country.townName">
                                                                        {{country.townName}}</mat-option>
                                                                </mat-optgroup>
                                                                <mat-optgroup label="Oceania">
                                                                    <mat-option *ngFor="let country of oceania"
                                                                        [value]="country.townName">
                                                                        {{country.townName}}</mat-option>
                                                                </mat-optgroup>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <mat-form-field id="identityNumber" style="width: 100%;">
                                                            <input matInput placeholder="Numero documento *"
                                                                formControlName="identityNumber" maxlength="30"
                                                                autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <!--
                                                <div class="row no-gutters">
                                                    <div class="col-sm-6">
                                                        <strong>Carica le foto del documento *</strong>
                                                        <br>(dimensione massima: 450 KB ciascuna)
                                                    </div>
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col-sm-6 d-flex justify-content-center">
                                                        <app-document-image-upload buttonLabel="lato A "
                                                            [fetchCallback]="fetchCallback"
                                                            (imageId)="onSetImageId($event, 'front')">
                                                        </app-document-image-upload>
                                                    </div>
                                                    <div class="col-sm-6 d-flex justify-content-center">
                                                        <app-document-image-upload buttonLabel="lato B "
                                                            [fetchCallback]="fetchCallback"
                                                            (imageId)="onSetImageId($event, 'back')">
                                                        </app-document-image-upload>
                                                    </div>
                                                </div>
                                                -->
                                                <div class="row no-gutters">
                                                    <div class="col">
                                                        <div class="my-alert-danger"
                                                            *ngIf="!isIdentityNumberFocused && identityNumber.hasError('pattern')">
                                                            Il numero del documento di identità contiene caratteri non
                                                            validi.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="container">
                                                <div class="row no-gutters">
                                                    <div class="col-sm-6">
                                                        <h3><strong>Residenza</strong></h3>
                                                    </div>
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col-sm-3">
                                                        <mat-form-field id="region" style="width: 100%">
                                                            <mat-label>Regione *</mat-label>
                                                            <mat-select formControlName="residenceRegion"
                                                                (selectionChange)="onRegionSelectionChange()">
                                                                <mat-option *ngFor="let region of regions"
                                                                    [value]="region">
                                                                    {{region}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <mat-form-field id="province" style="width: 100%">
                                                            <mat-label *ngIf="residenceRegion.value !== 'Estero'">
                                                                Provincia *
                                                            </mat-label>
                                                            <mat-label *ngIf="residenceRegion.value === 'Estero'">
                                                                Continente *
                                                            </mat-label>
                                                            <mat-select formControlName="residenceProvince"
                                                                (selectionChange)="onProvinceSelectionChange()">
                                                                <mat-option *ngFor="let province of residenceProvinces"
                                                                    [value]="province.provinceCode">
                                                                    {{province.provinceName}}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <mat-form-field id="town" style="width: 100%">
                                                            <mat-label *ngIf="residenceRegion.value !== 'Estero'">Comune
                                                                *
                                                            </mat-label>
                                                            <mat-label *ngIf="residenceRegion.value === 'Estero'">Stato
                                                                o territorio estero *
                                                            </mat-label>
                                                            <mat-select formControlName="residenceTown"
                                                                (selectionChange)="onTownSelectionChange()">
                                                                <mat-option *ngFor="let town of residenceTowns"
                                                                    [value]="town.townId">
                                                                    {{town.townName}}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col">
                                                        <mat-form-field id="address" style="width: 100%">
                                                            <input matInput placeholder="Indirizzo *"
                                                                formControlName="address" maxlength="70"
                                                                autocomplete="off">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col">
                                                        <div class="my-alert-danger"
                                                            *ngIf="!isAddressFocused && address.hasError('pattern')">
                                                            L'indirizzo contiene caratteri non validi.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="container">
                                                <div class="d-flex justify-content-between" style="padding-left: 6px">
                                                    <h3><strong>Domicilio e/o luogo dell'attività politica</strong></h3>
                                                    <button mat-icon-button class="info-button" color="primary"
                                                        matTooltip="Aiuto"
                                                        aria-label="Bottone per aprire le informazioni sulla sezione"
                                                        (click)="onOpenInfo(2)"
                                                        [ngClass]="mobileQuery.matches ? 'mr-1' : ''">
                                                        <mat-icon fontSet="material-icons-round" class="info-icon">help
                                                        </mat-icon>
                                                    </button>
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col">
                                                        <mat-checkbox (click)="onDifferentPoliticalResidenceChange()">
                                                            Seleziona
                                                            se
                                                            diverso
                                                            dalla
                                                            residenza</mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col-sm-3">
                                                        <mat-form-field id="politicalRegion" style="width: 100%">
                                                            <mat-label>Regione *</mat-label>
                                                            <mat-select formControlName="politicalRegion"
                                                                (selectionChange)="onPoliticalRegionSelectionChange()">
                                                                <mat-option *ngFor="let region of regions"
                                                                    [value]="region">
                                                                    {{region}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <mat-form-field id="politicalProvince" style="width: 100%">
                                                            <mat-label *ngIf="politicalRegion.value !== 'Estero'">
                                                                Provincia *
                                                            </mat-label>
                                                            <mat-label *ngIf="politicalRegion.value === 'Estero'">
                                                                Continente *
                                                            </mat-label>
                                                            <mat-select formControlName="politicalProvince"
                                                                (selectionChange)="onPoliticalProvinceSelectionChange()">
                                                                <mat-option *ngFor="let province of politicalProvinces"
                                                                    [value]="province.provinceCode">
                                                                    {{province.provinceName}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <mat-form-field id="politicalTown" style="width: 100%">
                                                            <mat-label *ngIf="politicalRegion.value !== 'Estero'">Comune
                                                                *
                                                            </mat-label>
                                                            <mat-label *ngIf="politicalRegion.value === 'Estero'">Stato
                                                                o territorio estero *
                                                            </mat-label>
                                                            <mat-select formControlName="politicalTown">
                                                                <mat-option *ngFor="let town of politicalTowns"
                                                                    [value]="town.townId">
                                                                    {{town.townName}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col">
                                                        <mat-form-field id="assembly" style="width: 100%">
                                                            <input matInput placeholder="Assemblea (facoltativo)"
                                                                formControlName="assembly" maxlength="50"
                                                                autocomplete="off" (focus)="isAssemblyFocused = true"
                                                                (blur)="isAssemblyFocused = false">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col">
                                                        <div class="my-alert-danger"
                                                            *ngIf="!isAssemblyFocused && assembly.hasError('pattern')">
                                                            L'assemblea contiene caratteri non validi.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="container">
                                                <div class="row no-gutters">
                                                    <div class="col-sm-6">
                                                        <h3><strong>Contatti</strong></h3>
                                                    </div>
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col-sm-6">
                                                        <mat-form-field id="email" style="width: 100%">
                                                            <input matInput placeholder="Indirizzo email *"
                                                                formControlName="email" maxlength="70" readonly>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <mat-form-field id="phoneNumber" style="width: 100%">
                                                            <input matInput placeholder="Numero di telefono *"
                                                                formControlName="phoneNumber" maxlength="20"
                                                                autocomplete="off" (focus)="isPhoneNumberFocused = true"
                                                                (blur)="isPhoneNumberFocused = false">
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row no-gutters">
                                                    <div class="col">
                                                        <div class="my-alert-danger"
                                                            *ngIf="(!isPhoneNumberFocused && phoneNumber.hasError('pattern'))">
                                                            <div *ngIf="phoneNumber.hasError('pattern')">
                                                                Il numero di telefono contiene caratteri non validi.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="container-no-borders"></div>
                                            <div class="row no-gutters"
                                                style="display: flex; justify-content: flex-end;">
                                                <div class="px-3 mb-1">
                                                    <button mat-stroked-button color="primary" matStepperNext
                                                        [disabled]="!userDataForm.valid">Avanti</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </mat-step>
                            <!--
                              **********************************************************************************
                              ********************************   PRIVACY   *************************************
                              **********************************************************************************
                            -->
                            <mat-step id="privacy" [stepControl]="privacyForm"
                                [label]="mobileQuery.matches ? '' : 'Informativa sulla privacy'">
                                <form [formGroup]="privacyForm">
                                    <div style="overflow-y: auto; height: calc(100vh - 170px);">
                                        <div class="p-2 mx-2">
                                            <app-privacy></app-privacy>
                                            <p><strong>
                                                    Acconsento in modo esplicito, libero, specifico, informato e
                                                    inequivocabile al trattamento dei dati che riguardano le mie
                                                    opinioni
                                                    politiche, nei termini, modalità e finalità di cui alla suddetta
                                                    informativa, nonché ai sensi dell'articolo 9, par. 2, lett. a) o d)
                                                    del
                                                    Regolamento 2016/679. Sono stato informato che i miei dati non
                                                    saranno
                                                    mai utilizzati per finalità commerciali. Acconsento ad essere
                                                    successivamente ricontattato da PAP con comunicazioni di propaganda
                                                    politica ed elettorale in linea con le finalità statutarie, anche a
                                                    mezzo sms, e-mail, messaggistica istantanea e social network.
                                                </strong>
                                            </p>
                                            <div
                                                class="white-bg-yellow-border-container d-flex justify-content-start align-items-start my-1">
                                                <mat-checkbox formControlName="trattamento" class="mr-2"></mat-checkbox>
                                                <strong>Si, acconsento al trattamento dei miei dati personali, anche
                                                    sensibili, all'associazione avente denominazione PAP (Regolamento UE
                                                    2016/679)</strong>
                                            </div>
                                            <div
                                                class="white-bg-yellow-border-container d-flex justify-content-start align-items-start my-1">
                                                <mat-checkbox formControlName="autenticita" class="mr-2"></mat-checkbox>
                                                <strong>Dichiaro che i dati inseriti, ai sensi del DPR 445/2000,
                                                    all'atto della registrazione sono autentici, completi e
                                                    corrispondono all'utente che ha eseguito la registrazione
                                                    stessa</strong>
                                            </div>
                                            <ng-container *ngIf="isMinorenne()">
                                                <div class="white-bg-yellow-border-container d-flex justify-content-start align-items-start my-1">
                                                    <mat-checkbox formControlName="minorenni" class="mr-2"></mat-checkbox>
                                                    <strong>Dichiaro di aver l'autorizzazione dei miei genitori o del mio
                                                        tutore legale per completare l'operazione di adesione a Potere al
                                                        Popolo</strong>
                                                </div>
                                            </ng-container>
                                            <div
                                                class="white-bg-yellow-border-container d-flex justify-content-start align-items-start">
                                                <mat-checkbox formControlName="trasparenza" class="mr-2"></mat-checkbox>
                                                <strong class="trasparenza-detail"
                                                    (click)="onOpenTrasparenzaDetail()">Autodichiarazione ex L.
                                                    3/2019 sulla Trasparenza Elettorale (Facoltativo, clicca per
                                                    leggere)</strong>
                                            </div>
                                            <div style="display: flex; justify-content: flex-end;">
                                                <div class="px-3 mb-1">
                                                    <button mat-stroked-button color="primary" matStepperNext
                                                        (click)="onSubmit()"
                                                        [disabled]="!privacyForm.valid">Avanti</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </mat-step>
                            <!--
                                ************************************************************************************
                                *********************************   PAGAMENTO   ************************************
                                ************************************************************************************
                              -->
                            <mat-step id="payment" [label]="mobileQuery.matches ? '' : 'Pagamento'">
                                <div style="overflow-y: auto; height: calc(100vh - 170px); padding: 0 6px">
                                    <div [ngClass]="mobileQuery.matches ? '' : 'short-text-step'">
                                        <div class="p-2 mw-500">
                                            <app-payment [email]="emailSubmitted"></app-payment>
                                        </div>
                                    </div>
                                </div>
                            </mat-step>
                        </mat-horizontal-stepper>
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </main>
</div>
