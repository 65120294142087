import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { LoginFailedComponent } from 'src/app/authentication/login-failed/login-failed.component';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('f', { static: true }) form: NgForm;
  email: string;
  password: string;
  isLoading = false;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() { }

  login() {
    this.isLoading = true;
    this.authService.signIn(this.form.value.email, this.form.value.password).then(async (result) => {
      const token = await result.user.getIdToken();
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('manager', this.form.value.email.trim().toLowerCase());
      this.authService.isAdmin().pipe(catchError(this.unauthorized)).subscribe((data: any) => {
        if (data.code === 200) {
          this.isLoading = false;
          this.router.navigate(['/ad2020']);
        } else {
          this.unauthorized(new HttpErrorResponse({}));
        }
      });
    }).catch((error) => {
      this.form.reset();
      const dialogMessage = {
        title: 'Accesso fallito',
        message: 'Email o password non sono corretti'
      } as any;
      this.dialog.open(LoginFailedComponent, {
        width: '300px',
        height: '200px',
        disableClose: true,
        data: dialogMessage
      });
      this.resetAll();
    });
  }

  unauthorized(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
    } else {
      // server-side error
      errorMessage = 'Non autorizzato a procedere.';
    }
    window.alert(errorMessage);
    this.resetAll();
    return of(null);
  }

  resetAll() {
    this.isLoading = false;
    this.email = null;
    this.password = null;
  }
}
