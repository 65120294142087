// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const firebaseConfig = {
  apiKey: 'AIzaSyA4NlcnmGg-FLw2HfNsvTerWEfrDTcgwoQ',
  authDomain: 'adesioni-2020.firebaseapp.com',
  databaseURL: 'https://adesioni-2020.firebaseio.com',
  projectId: 'adesioni-2020',
  storageBucket: 'adesioni-2020.appspot.com',
  messagingSenderId: '462851785757',
  appId: '1:462851785757:web:0f349fb57bbcb63e21f2f4'
};

const server = 'https://node.poterealpopolo.org:8443';
const testServer = 'http://localhost:8080';

export const environment = {
  production: false,
  apis: {
    signup: server + '/signup',
    territories: server + '/territories',
    demands: server + '/demands',
    operations: server + '/operations',
    stats: server + '/stats',
    isAdmin: server + '/isAdmin',
  },
  domain: 'https://adesioni.poterealpopolo.org',
  testdomain: 'http://localhost:4300',
  firebase: firebaseConfig,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
