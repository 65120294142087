import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogMessage {
  title: string;
  message: string;
}

@Component({
  selector: 'app-login-failed',
  template: `<app-generic-dialog-content [title]="title">
             <div id = "body">
                <p>{{message}}</p>
             </div>
             <div id = "footer">
                <button mat-flat-button color="primary" mat-dialog-close>Ok</button>
             </div>
             </app-generic-dialog-content>`
})
export class LoginFailedComponent implements OnInit {

  title: string;
  message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogMessage,
  ) { }

  ngOnInit(): void {
    this.message = this.data.message;
    this.title = this.data.title;
  }

}
