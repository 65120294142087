import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginFailedComponent } from 'src/app/authentication/login-failed/login-failed.component';

@Injectable()
export class AuthenticationService {

  userData: Observable<firebase.User>;
  token: string;

  constructor(
    public afAuth: AngularFireAuth,
    private http: HttpClient,
    private dialog: MatDialog,
  ) {
    this.signOut();
    this.userData = afAuth.authState;
  }

  signIn(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  signInWithEmailLink(email: string, url: string) {
    return this.afAuth.auth.signInWithEmailLink(email, url).catch(this.authFailed);
  }

  signOut() {
    this.afAuth.auth.signOut().catch(this.authError);
  }

  isSignInWithEmailLink(url: string) {
    return this.afAuth.auth.isSignInWithEmailLink(url);
  }

  getUserData() {
    return this.userData;
  }

  getToken() {
    const token = sessionStorage.getItem('token');
    return token;
  }

  isAdmin() {
    return this.http.get(environment.apis.isAdmin);
  }

  authFailed(error: any) {
    window.alert('Il link utilizzato è scaduto');
  }

  authError(error: any) {
    const dialogMessage = {
      title: 'Qualcosa è andato storto',
      message: 'Ricomincia da capo la procedura'
    } as any;
    this.dialog.open(LoginFailedComponent, {
      width: '300px',
      height: '200px',
      disableClose: true,
      data: dialogMessage
    });
  }
}
