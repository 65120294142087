import { Component } from '@angular/core';

@Component({
  selector: 'app-info-domicilio',
  template: ` <div class="d-flex flex-column">
                <div class="flex-1">
                    <div class="overflow-y-auto">
                        <h2>Domicilio e/o luogo dell\'attività politica</h2>
                        <p>
                                Se vivi o svolgi attività politica in un luogo diverso da quello di residenza puoi
                                inserire questa informazione aggiuntiva.<br>È un <strong>dato importante</strong>
                                perché servirà a determinare a quali <strong>elezioni interne a Potere al Popolo!</strong>
                                potrai partecipare: in altre parole determina il bacino elettorale territoriale a cui
                                appartieni (comune, provincia e regione).<br>
                                <strong>Se non indichi nulla verranno automaticamente impostate in base all’indirizzo di residenza.</strong>
                        </p>
                    </div>
                </div>

                <div class="d-flex justify-content-end" style="height: 28px">
                    <button style="width: 0px; height: 0px;" cdkFocusInitial mat-button></button>
                    <button mat-flat-button color="primary" mat-dialog-close>Ok</button>
                </div>
              </div>`
})
export class InfoDomicilioComponent {
  constructor() { }
}

