import { Component, OnInit, Input } from '@angular/core';

declare var Stripe: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @Input() email: string;

  amount = 15;

  sku: string;

  constructor() { }

  ngOnInit(): void {
  }

  setAmount(dir: boolean) {
    if (dir && (this.amount < 100)) {
      this.amount += 5;
    } else if (!dir && (this.amount > 15)) {
      this.amount -= 5;
    } else {  }
  }

  buy() {
    sessionStorage.removeItem('token');
    switch (this.amount) {
      case 15: this.sku = 'sku_HITGH7gXZHNdCx'; break;
      case 20: this.sku = 'sku_HITGO4g0zdjVDf'; break;
      case 25: this.sku = 'sku_HITHkPEPf3Gi6N'; break;
      case 30: this.sku = 'sku_HITHLx7N2wuQ3z'; break;
      case 35: this.sku = 'sku_HITI28BTG6ut1K'; break;
      case 40: this.sku = 'sku_HITIHLsJU0rrzc'; break;
      case 45: this.sku = 'sku_HITJ3621qkw1zl'; break;
      case 50: this.sku = 'sku_HITJVB37JmCfaD'; break;
      case 55: this.sku = 'sku_HITR29Q1pNHh6L'; break;
      case 60: this.sku = 'sku_HITSJGvntuQYm3'; break;
      case 65: this.sku = 'sku_HITS9AmC3y2c94'; break;
      case 70: this.sku = 'sku_HITSf8EFJu9mM2'; break;
      case 75: this.sku = 'sku_HITTpgFCF7wbiU'; break;
      case 80: this.sku = 'sku_HITTTS4ofb5D5e'; break;
      case 85: this.sku = 'sku_HITTu5E196rnSF'; break;
      case 90: this.sku = 'sku_HITUtwYZkNc1mt'; break;
      case 95: this.sku = 'sku_HITU8aJWsFo8pO'; break;
      case 100: this.sku = 'sku_HITURnvQfq87wI'; break;
      default: this.sku = '';
    }
    const stripe = Stripe('pk_live_LcWzdim7j3uPn21324GADvKf00zHETxZ5Z');
    stripe.redirectToCheckout({
      items: [{sku: this.sku, quantity: 1}],
      successUrl: 'https://poterealpopolo.org/adesione-successo/',
      cancelUrl: 'https://poterealpopolo.org/adesione-errore/',
      customerEmail: this.email,
    }).then((result) => {
      if (result.error) {
        const displayError = document.getElementById('error-message');
        displayError.textContent = result.error.message;
      }
    });
  }

}
