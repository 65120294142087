import { catchError } from 'rxjs/operators';

import { AdherentReceived } from 'src/app/shared/models/adherent-received.model';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ReInsertEmailComponent } from './re-insert-email/re-insert-email.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TerritoriesService } from 'src/app/shared/services/territories.service';
import { Town, Province } from 'src/app/shared/models/territories.model';
import { MediaMatcher } from '@angular/cdk/layout';
import { SignUpService } from 'src/app/shared/services/sign-up.service';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { InfoDomicilioComponent } from 'src/app/adesioni/stepper/dialogs/info-domicilio.component';
import { InfoDocumentoComponent } from 'src/app/adesioni/stepper/dialogs/info-documento.component';
import { InfoGeneraliComponent } from 'src/app/adesioni/stepper/dialogs/info-generali.component';
import { of } from 'rxjs';
import { TrasparenzaDetailComponent } from 'src/app/adesioni/stepper/dialogs/trasparenza-detail.component';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, OnDestroy {

  waitingMessage: string;
  loading: boolean;
  forbidden: boolean;
  emailExists: boolean;
  showPayment = false;
  panelOpenState = false;

  mobileQuery: MediaQueryList;

  private mobileQueryListener: () => void;

  // forms
  userDataForm: FormGroup;
  privacyForm: FormGroup;

  // constant territories arrays
  regions = new Array<string>();
  europe = new Array<Town>();
  africa = new Array<Town>();
  asia = new Array<Town>();
  america = new Array<Town>();
  oceania = new Array<Town>();

  // variable territories arrays
  residenceProvinces = new Array<Province>();
  politicalProvinces = new Array<Province>();
  residenceTowns = new Array<Town>();
  politicalTowns = new Array<Town>();

  // dates parameters
  startDate = new Date('1990');
  subscriptionThreshold = new Date('2006-12-31T00:00:00');

  // states parameters
  checked = false;
  isNameFocused = false;
  isSurnameFocused = false;
  isBirthPlaceFocused = false;
  isBirthDayFocused = false;
  isBirthMonthFocused = false;
  isBirthYearFocused = false;
  isIdentityNumberFocused = false;
  isAddressFocused = false;
  isFiscalCodeFocused = false;
  isPhoneNumberFocused = false;
  isAssemblyFocused = false;
  differentPoliticalResidence = false;
  formSubmitted = false;

  // id after insert in DB
  adherentId: number;
  emailSubmitted: string;

  fetchCallback = (upLoadFormData: FormData) => this.signUpService.uploadImage(upLoadFormData);

  constructor(
    public authService: AuthenticationService,
    private router: Router,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private territories: TerritoriesService,
    private signUpService: SignUpService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 500px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  ngOnInit() {
    this.waitingMessage = '';
    this.loading = true;
    this.forbidden = true;
    this.emailExists = false;

    const url = this.router.url;

    this.confirmSignIn(url);

    // init forms
    this.userDataForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(50),
      Validators.pattern('[a-zA-ZÀ-ÖÙ-öù-ü \-\']*$')])],
      surname: ['', Validators.compose([Validators.required, Validators.maxLength(50),
      Validators.pattern('[a-zA-ZÀ-ÖÙ-öù-ü \-\']*$')])],
      birthPlace: ['', Validators.compose([Validators.required, Validators.maxLength(70),
      Validators.pattern('[a-zA-ZÀ-ÖÙ-öù-ü() \-\']*$')])],
      birthDay: ['', Validators.compose([Validators.required,
      Validators.maxLength(2), Validators.max(31), Validators.min(1), Validators.pattern('[0-9]*$')])],
      birthMonth: ['', Validators.compose([Validators.required,
      Validators.maxLength(2), Validators.max(12), Validators.min(1), Validators.pattern('[0-9]*$')])],
      birthYear: ['', Validators.compose([Validators.required,
      Validators.maxLength(4), Validators.max(2006), Validators.min(1871), Validators.pattern('[0-9]*$')])],
      fiscalCode: ['', Validators.compose([Validators.required, Validators.maxLength(16), Validators.minLength(16),
      Validators.pattern('^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9A-Za-z]{3}[A-Za-z]{1}$')])],
      documentType: ['', Validators.required],
      identityNumber: ['', Validators.compose([Validators.required, Validators.maxLength(30),
      Validators.pattern('[a-zA-Z0-9 \-/]*$')])],
      passportCountry: [{ value: '', disabled: true }],
      // isUploadedImage: ['', Validators.required],
      //documentFrontImageId: ['', Validators.required],
      // documentBackImageId: ['', Validators.required],
      residenceRegion: ['', Validators.required],
      residenceProvince: ['', Validators.required],
      residenceTown: ['', Validators.required],
      address: ['', Validators.compose([Validators.required, Validators.maxLength(70)])],
      politicalRegion: [{ value: '', disabled: true }, Validators.required],
      politicalProvince: [{ value: '', disabled: true }, Validators.required],
      politicalTown: [{ value: '', disabled: true }, Validators.required],
      assembly: ['', Validators.compose([Validators.maxLength(50),
      Validators.pattern('[a-zA-ZÀ-ÖÙ-öù-ü \-\']*$')])],
      email: ['', Validators.required],
      phoneNumber: ['', Validators.compose([Validators.required,
      Validators.maxLength(20), Validators.pattern('[0-9+\-]*$')])]
    });

    this.privacyForm = this.formBuilder.group({
      trattamento: [false, Validators.requiredTrue],
      autenticita: [false, Validators.requiredTrue],
      minorenni: [false, Validators.requiredTrue],
      trasparenza: [false],
    });

    // init territories
    this.regions = this.territories.getRegions();

    // init controls
    // this.isUploadedImage.setValue(true);
    this.passportCountry.setValue('Italia');

  }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  // controls getters
  get name() { return this.userDataForm.get('name'); }
  get surname() { return this.userDataForm.get('surname'); }
  get birthPlace() { return this.userDataForm.get('birthPlace'); }
  get birthDay() { return this.userDataForm.get('birthDay'); }
  get birthMonth() { return this.userDataForm.get('birthMonth'); }
  get birthYear() { return this.userDataForm.get('birthYear'); }
  get fiscalCode() { return this.userDataForm.get('fiscalCode'); }
  get passportCountry() { return this.userDataForm.get('passportCountry'); }
  get documentType() { return this.userDataForm.get('documentType'); }
  get identityNumber() { return this.userDataForm.get('identityNumber'); }
  // get documentFrontImageId() { return this.userDataForm.get('documentFrontImageId'); }
  // get documentBackImageId() { return this.userDataForm.get('documentBackImageId'); }
  get residenceRegion() { return this.userDataForm.get('residenceRegion'); }
  get residenceProvince() { return this.userDataForm.get('residenceProvince'); }
  get residenceTown() { return this.userDataForm.get('residenceTown'); }
  get address() { return this.userDataForm.get('address'); }
  get politicalRegion() { return this.userDataForm.get('politicalRegion'); }
  get politicalProvince() { return this.userDataForm.get('politicalProvince'); }
  get politicalTown() { return this.userDataForm.get('politicalTown'); }
  get assembly() { return this.userDataForm.get('assembly'); }
  get email() { return this.userDataForm.get('email'); }
  get phoneNumber() { return this.userDataForm.get('phoneNumber'); }

  async confirmSignIn(url) {
    try {
      if (this.authService.isSignInWithEmailLink(url)) {
        const email = localStorage.getItem('emailForSignIn');
        // If missing email, prompt user for it
        if (email) {
          this.auth(email, url);
        } else {
          this.reInsertEmailAndAuth(url);
        }
      }
    } catch (err) {
      this.loading = false;
      this.forbidden = true;
      this.emailExists = false;
    }
  }

  async reInsertEmailAndAuth(url: string) {
    const dialogRef = this.dialog.open(ReInsertEmailComponent, {
      width: '300px',
      height: '240px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((email: any) => {
      if (email === null || email === undefined) {
      } else {
        this.auth(email, url);
      }
    }, (err: HttpErrorResponse) => {
      this.loading = false;
      this.forbidden = true;
      this.emailExists = false;
    });
  }

  async auth(email: string, url: string) {
    // Signin user and remove the email sessionStorage
    const result = await this.authService.signInWithEmailLink(email, url);
    if (result && result.user.emailVerified) {
      const token = await result.user.getIdToken();
      sessionStorage.setItem('token', token);
      this.emailSubmitted = result.user.email;
      this.authService.signOut();
      localStorage.removeItem('emailForSignIn');
      this.waitingMessage = 'Attendi ancora un minuto, stiamo verificando l\'indirizzo email';
      this.signUpService.check(email)
        .pipe(catchError((error: HttpErrorResponse) => {
          this.forbidden = true;
          if (error.status === 401) {
            this.emailExists = true;
          } else {
            this.emailExists = false;
          }
          return of(true);
        })).subscribe(
          (answer: AdherentReceived) => {

            if (result.user.emailVerified) {
              this.waitingMessage = '';
              this.loading = false;

              this.forbidden = answer == null;
              this.email.setValue(this.emailSubmitted);
              this.territories.getProvincesAPI(() => {this.residenceProvinces = this.territories.getProvincesByRegion(answer.residenceRegion); this.politicalProvinces = this.territories.getProvincesByRegion(answer.politicalRegion);});
              this.territories.getTownsAPI(() => {this.residenceTowns = this.territories.getTownsByProvince(answer.residenceProvince); this.politicalTowns = this.territories.getTownsByProvince(answer.politicalProvince)});
              if (answer != null) {                
                const tmpBirthDay = answer.birthDate.split('-')[0];
                const tmpBirthMonth = answer.birthDate.split('-')[1];
                const tmpBirthYear = answer.birthDate.split('-')[2];            
                const tmpDocumentType = answer.documentId.split(' ')[0];
                var tmpPassportCountry = '';
                var tmpIdentityNumber = '';
                if(tmpDocumentType === 'PAS.'){
                  tmpPassportCountry = answer.documentId.split(' ')[1];  
                  tmpIdentityNumber = answer.documentId.split(' ')[2];  
                }else{
                  tmpIdentityNumber = answer.documentId.split(' ')[1];
                }
                
                this.userDataForm.setValue({
                  name: answer.name,
                  surname: answer.surname,
                  birthPlace: answer.birthPlace,
                  birthDay: tmpBirthDay,
                  birthMonth: tmpBirthMonth,
                  birthYear: tmpBirthYear,
                  fiscalCode: answer.fiscalCode,
                  passportCountry: tmpPassportCountry,
                  documentType: tmpDocumentType,
                  identityNumber: tmpIdentityNumber,
                  address: answer.address,
                  assembly: answer.assembly,
                  email: answer.email,
                  phoneNumber: answer.phoneNumber,
                  residenceRegion: answer.residenceRegion,
                  politicalRegion: answer.politicalRegion,
                  residenceProvince: answer.residenceProvince,
                  politicalProvince: answer.politicalProvince,
                  residenceTown: answer.residenceTown,
                  politicalTown: answer.politicalTown
                });
              }
            }
          });
    } else {
      this.router.navigate(['']);
    }
  }

  // on changes
  onDocumentTypeSelectionChange() {
    if (this.documentType.value === 'PAS.') {
      this.europe = this.territories.getCountriesByContinent('EU');
      this.africa = this.territories.getCountriesByContinent('AF');
      this.america = this.territories.getCountriesByContinent('AM');
      this.asia = this.territories.getCountriesByContinent('AS');
      this.oceania = this.territories.getCountriesByContinent('OC');
      this.passportCountry.enable();
    } else {
      this.passportCountry.setValue('Italia');
      this.passportCountry.disable();
    }
  }

  onRegionSelectionChange() {
    if (this.residenceRegion.value === 'Estero') {
      this.residenceProvinces = this.territories.getContinents();
    } else {
      this.residenceProvinces = this.territories.getProvincesByRegion(this.residenceRegion.value);
    }
    this.residenceTowns = [];
    this.residenceTown.setValue(null);
    if (this.differentPoliticalResidence) { } else {
      this.politicalRegion.setValue(this.residenceRegion.value);
    }
  }

  onProvinceSelectionChange() {
    if (this.residenceRegion.value === 'Estero') {
      this.residenceTowns = this.territories.getCountriesByContinent(this.residenceProvince.value);
      this.residenceTown.setValue(null);
    } else {
      this.residenceTowns = this.territories.getTownsByProvince(this.residenceProvince.value);
      this.residenceTown.setValue(null);
    }
    if (this.differentPoliticalResidence) { } else {
      this.politicalProvinces = this.residenceProvinces;
      this.politicalProvince.setValue(this.residenceProvince.value);
      this.politicalTown.setValue(null);
    }
  }

  onTownSelectionChange() {
    if (this.differentPoliticalResidence) { } else {
      this.politicalTowns = this.residenceTowns;
      this.politicalTown.setValue(this.residenceTown.value);
    }
  }

  onPoliticalRegionSelectionChange() {
    if (this.politicalRegion.value === 'Estero') {
      this.politicalProvinces = this.territories.getContinents();
    } else {
      this.politicalProvinces = this.territories.getProvincesByRegion(this.politicalRegion.value);
    }
    this.politicalTowns = [];
    this.politicalTown.setValue(null);
  }

  onPoliticalProvinceSelectionChange() {
    if (this.politicalRegion.value === 'Estero') {
      this.politicalTowns = this.territories.getCountriesByContinent(this.politicalProvince.value);
      this.politicalTown.setValue(null);
    } else {
      this.politicalTowns = this.territories.getTownsByProvince(this.politicalProvince.value);
      this.politicalTown.setValue(null);
    }
  }

  onDifferentPoliticalResidenceChange() {
    this.differentPoliticalResidence = !this.differentPoliticalResidence;
    if (this.differentPoliticalResidence) {
      this.politicalRegion.enable();
      this.politicalProvince.enable();
      this.politicalTown.enable();
    } else {
      this.politicalRegion.disable();
      this.politicalProvince.disable();
      this.politicalTown.disable();
      this.politicalRegion.setValue(this.residenceRegion.value);
      this.politicalProvinces = this.residenceProvinces;
      this.politicalProvince.setValue(this.residenceProvince.value);
      this.politicalTowns = this.residenceTowns;
      this.politicalTown.setValue(this.residenceTown.value);
    }
  }

  // set from child component event
  /* onSetImageId(imageId: number, type: string) {
    if (type === 'front') {
      this.documentFrontImageId.setValue(imageId);
    } else if (type === 'back') {
      this.documentBackImageId.setValue(imageId);
    } else { }

  } */

  onOpenInfo(code: number) {
    switch (code) {
      case 0:
        this.dialog.open(InfoGeneraliComponent, {
          width: '400px',
          height: '280px',
          disableClose: true,
        });
        break;
      /* case 1:
        this.dialog.open(InfoDocumentoComponent, {
          width: '500px',
          height: '400px',
          disableClose: true,
        });
        break; */
      case 2:
        this.dialog.open(InfoDomicilioComponent, {
          width: '500px',
          height: '300px',
          disableClose: true,
        });
        break;
    }
  }

  onOpenTrasparenzaDetail() {
    this.dialog.open(TrasparenzaDetailComponent, {
      width: '500px',
      height: '400px',
      disableClose: true,
    });
  }

  isMinorenne() {
    const today = new Date();
    if (!this.userDataForm.valid || (+this.birthYear.value > 2002) ||
      ((+this.birthYear.value === 2002) && (+this.birthMonth.value > (today.getMonth() + 1))) ||
      ((+this.birthYear.value === 2002) && (+this.birthMonth.value === (today.getMonth() + 1)) &&
        (+this.birthDay.value > today.getDate()))) {

      if (this.birthDay.valid && this.birthMonth.valid && this.birthDay.valid && !this.privacyForm.get('trattamento').value) { this.privacyForm.get('minorenni').setValue(false); }
      return true;

    } else {
      this.privacyForm.get('minorenni').setValue(true);
      return false;
    }
  }

  // submit user data form
  onSubmit() {
    const adherent = new AdherentReceived();
    adherent.email = this.email.value;
    adherent.name = this.name.value.trim();
    adherent.surname = this.surname.value.trim();
    adherent.birthPlace = this.birthPlace.value.trim();
    adherent.fiscalCode = this.fiscalCode.value.trim().toUpperCase();
    adherent.phoneNumber = this.phoneNumber.value.trim();
    // adherent.documentFrontImageId = this.documentFrontImageId.value;
    // adherent.documentBackImageId = this.documentBackImageId.value;
    adherent.documentFrontImageId = 3502;
    adherent.documentBackImageId = 3502;
    adherent.residenceRegion = this.residenceRegion.value;
    adherent.residenceProvince = this.residenceProvince.value;
    adherent.residenceTown = this.residenceTown.value;
    adherent.address = this.address.value.trim();
    adherent.politicalRegion = this.politicalRegion.value;
    adherent.politicalProvince = this.politicalProvince.value;
    adherent.politicalTown = this.politicalTown.value;
    adherent.assembly = this.assembly.value;

    let documentId = (this.documentType.value + ' ' + this.identityNumber.value.trim().toUpperCase()) as string;
    if (this.documentType.value === 'PAS.') {
      documentId = documentId + ' (' + this.passportCountry.value + ')';
    }
    adherent.documentId = documentId;
    adherent.birthDate = this.birthDay.value + '-' + this.birthMonth.value + '-' + this.birthYear.value;
    this.signUpService.signUp(adherent).subscribe((data) => {
      if (data !== 0) {
        this.userDataForm.reset();
        this.privacyForm.reset();
        this.formSubmitted = true;
      } else {
        this.router.navigate(['/']);
      }
    }
    );
  }
}
