import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'adesioni-2020';
  cookieMessage = 'Questo sito web utilizza i cookies, anche di terze parti. ' +
                  'Proseguendo la navigazione si acconsente all\'utilizzo dei cookies.';
  cookieDismiss = 'OK';
  cookieLinkText = 'Per saperne di più clicca qui';

  constructor(
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    const cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#164969'
        },
        button: {
          background: '#ffe000',
          text: '#164969'
        }
      },
      theme: 'classic',
      content: {
        message: this.cookieMessage,
        dismiss: this.cookieDismiss,
        link: this.cookieLinkText,
        href: environment.domain + '/cookies',
      }
    });
  }
}
