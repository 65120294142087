import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/authentication/authentication.service';

@Injectable()
export class AppGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) { }

  canActivate():
    boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authService.getUserData().pipe(
      take(1),
      map(user => !!user ? true : this.router.createUrlTree(['/ad-manager'])));
  }
}
