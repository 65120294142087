import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Province, Town } from '../models/territories.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

interface TerritoriesData {
  regions: Array<string>;
  continents: Array<Province>;
  provinces: Array<Province>;
  towns: Array<Town>;
}

export const apis = {
  provinces: environment.apis.territories + '/provinces',
  towns: environment.apis.territories + '/towns',
  assemblies: environment.apis.territories + '/assemblies',
};

@Injectable({
  providedIn: 'root'
})
export class TerritoriesService {

  data = {} as TerritoriesData;

  constructor(
    private http: HttpClient,
    private router: Router) {

    this.data.regions = ['Estero',
      'Abruzzo', 'Basilicata', 'Calabria', 'Campania',
      'Emilia-Romagna', 'Friuli-Venezia Giulia', 'Lazio',
      'Liguria', 'Lombardia', 'Marche', 'Molise', 'Piemonte',
      'Puglia', 'Sardegna', 'Sicilia', 'Toscana',
      'Trentino-Alto Adige', 'Umbria', 'Valle d\'Aosta', 'Veneto'];

    this.data.continents = [
      { provinceCode: 'AF', provinceName: 'Africa', region: 'Estero' },
      { provinceCode: 'AM', provinceName: 'America', region: 'Estero' },
      { provinceCode: 'AS', provinceName: 'Asia', region: 'Estero' },
      { provinceCode: 'EU', provinceName: 'Europa', region: 'Estero' },
      { provinceCode: 'OC', provinceName: 'Oceania', region: 'Estero' }];
  }

  getProvincesAPI(callback?: any) {
    this.http.get(apis.provinces)
      .subscribe((data: Array<Province>) => {
        this.data.provinces = data;
        if(callback){
          callback(data);
        }      
      });
  }

  getTownsAPI(callback?: any) {
    this.http.get(apis.towns)
      .subscribe((data: Array<Town>) => {
        this.data.towns = data;
        if(callback){
          callback(data);
        }
      });
  }

  getRegions() {
    return this.data.regions;
  }

  getContinents() {
    return this.data.continents;
  }

  getProvincesByRegion(region: string) {
    if (this.data.provinces) {
      const regionProvinces = this.data.provinces.filter(x => x.region === region);
      return regionProvinces.sort(this.sortByProvinceName);
    } else {
      this.getProvincesAPI();
      return null;
    }
  }

  getTownsByProvince(provinceCode: string) {
    if (this.data.towns) {
      const provinceTowns = this.data.towns.filter(x => x.provinceCode === provinceCode);
      return provinceTowns.sort(this.sortByTownName);
    } else {
      this.getTownsAPI();
      return null;
    }
  }

  getCountriesByContinent(code: string) {
    if (this.data.towns) {
      const countries = this.data.towns.filter(z => z.provinceCode === code);
      return countries.sort(this.sortByTownName);
    } else {
      this.getTownsAPI();
      return null;
    }
  }

  getTownById(id: number) {
    if (this.data.towns) {
      return this.data.towns.find(x => x.townId === id);
    } else {
      this.getTownsAPI();
      return null;
    }
  }

  getTerritoriesString(region: string, provinceCode: string, townId: number) {
    if (region === 'Estero') {
      return this.getTownNameById(townId) + ' (Estero)';
    } else {
      return this.getTownNameById(townId) + ' (' + provinceCode + ' / ' + region + ')';
    }
  }

  getTownNameById(id: number) {
    if (this.data.towns) {
      const town = this.data.towns.find(x => x.townId === id);
      if (town) {
        return town.townName;
      } else {
        return null;
      }
    } else {
      this.getTownsAPI();
      return null;
    }
  }

  private sortByTownName(a, b) {
    if (a.townName < b.townName) { return -1; }
    if (a.townName > b.townName) { return 1; }
    return 0;
  }

  private sortByProvinceName(a, b) {
    if (a.provinceName < b.provinceName) { return -1; }
    if (a.provinceName > b.provinceName) { return 1; }
    return 0;
  }
}
