import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-re-insert-email',
  templateUrl: './re-insert-email.component.html',
  styleUrls: ['./re-insert-email.component.scss']
})
export class ReInsertEmailComponent implements OnInit {

  email: FormControl;
  isEmailInvalid = false;

  constructor(public dialogRef: MatDialogRef<ReInsertEmailComponent>) { }

  ngOnInit(): void {
    this.email = new FormControl('', [ Validators.compose([Validators.required, Validators.email, Validators.maxLength(70)])]);
  }

  sendEmail() {
    this.email.setValue(this.email.value.trim().toLowerCase());
    if (this.email.valid) {
      this.isEmailInvalid = false;
      this.dialogRef.close(this.email.value);
    } else {
      this.isEmailInvalid = true;
    }

    // return this.email.value.trim().toLowerCase();
  }
}
