export class AdherentReceived {
    email: string;
    name: string;
    surname: string;
    birthPlace: string;
    birthDate: string;
    fiscalCode: string;
    phoneNumber: string;
    documentId: string;
    documentFrontImageId: number;
    documentBackImageId: number;
    residenceRegion: string;
    residenceProvince: string;
    residenceTown: number;
    address: string;
    politicalRegion: string;
    politicalProvince: string;
    politicalTown: number;
    assembly: string;

    constructor() {
        this.email = '';
        this.name = '';
        this.surname = '';
        this.birthPlace = '';
        this.birthDate = '';
        this.fiscalCode = '';
        this.phoneNumber = '';
        this.documentId = '';
        this.residenceRegion = '';
        this.residenceProvince = '';
        this.address = '';
        this.politicalRegion = '';
        this.politicalProvince = '';
        this.assembly = '';
    }
}
